<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth">
      <div class="row flex-grow">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left p-5">
            <div class="brand-logo">
              <img src="@/assets/images/logo.jpg">
            </div>
            <h4>Reset Password</h4>
            <form class="pt-3">
              <div class="form-group input-group">
                <input :type="show ? 'text' : 'password'" class="form-control form-control-lg" v-model="$v.password.$model" id="newpassword" placeholder="New Password" :class="{ 'is-invalid': $v.password.$error }">
                <span class="input-group-text">
                    <i :class="show ? 'mdi mdi-eye-off' : 'mdi mdi-eye'" id="togglePassword"
                   style="cursor: pointer" @mousedown="show = !show" @mouseup="show = !show" @touchstart="show = !show" @touchend="show = !show" ></i>
                </span>
                <div v-if="$v.password.$error" class="invalid-feedback">
                  <span v-if="!$v.password.required">Please enter new password</span>
                </div>                
              </div>
              <div class="form-group input-group">
                <input :type="showConfirmPassword ? 'text' : 'password'" class="form-control form-control-lg" v-model="$v.confirm_password.$model" id="confirmnewpassword" placeholder="Confirm New Password" :class="{ 'is-invalid': $v.confirm_password.$error }">
                <span class="input-group-text">
                    <i :class="showConfirmPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'" id="togglePassword"
                   style="cursor: pointer" @mousedown="showConfirmPassword = !showConfirmPassword" @mouseup="showConfirmPassword = !showConfirmPassword" @touchstart="showConfirmPassword = !showConfirmPassword" @touchend="showConfirmPassword = !showConfirmPassword" ></i>
                </span>
                <div v-if="$v.confirm_password.$error" class="invalid-feedback">
                  <span v-if="!$v.confirm_password.required">Please confirm new password</span>
                </div>
              </div>

              <div class="mt-3">
                <b-spinner v-if="is_btn_spinner" label=""></b-spinner>
                <button v-else type="button" class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" @click="ResetPassword">Reset Password</button>
              </div>
            </form>
          </div>
        </div>
        <Footer class="mt-5"/>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
</template>
<script>

import { required, numeric } from "vuelidate/lib/validators";
import Footer from "@/components/Footer.vue";
import { mapActions} from "vuex";
import string from "../constant/strings.js"

export default {
    name:'resetpasswords',
    title: string.PAGE_TITLE_RESET_PASSWORD,
  components: {Footer},

  data() {
    return {
      confirm_password:'',
      password:'',
      is_btn_spinner:false,
      show:false,
      showConfirmPassword:false,
    };
  },

  validations: {    
    password: {required},
    confirm_password:{required}
  },

  mounted() {
  },

   methods: {
       ...mapActions("hospital",["resetAdminPasswordData"]),

    ResetPassword() {
           this.is_btn_spinner = true;
           let url = new URL(window.location);
           let urlParams = new URLSearchParams(url.search);

          var bodyFormData = new FormData();
          bodyFormData.append('encoded_data', urlParams);
          bodyFormData.append('password', this.confirm_password);
          this.resetAdminPasswordData(bodyFormData).then((response) => {
             this.is_btn_spinner = false;
             if (response.response_code == 200) {
                this.$toasted.success(response.message, {duration: 2000,});
                this.$router.push({ name: "login" });
             } else {
                 this.$toasted.error(response.message, {duration: 2000,});
            }
        });
      }
    },
};
</script>